import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Seo from '../components/molecules/Seo'
import Container from '../components/atoms/Container'
import TextBlock from '../components/molecules/TextBlock'
import ImageBlock from '../components/molecules/ImageBlock'
import CenteredContent from '../components/molecules/CenteredContent'
import TitleBar from '../components/molecules/TitleBar'
import FeatureImage from '../components/molecules/FeatureImage'
import Next from '../components/organisms/Next'
import Array from '../components/atoms/Array'
import useEscapeKey from '../hooks/useEscapeKey'
import PageLightbox from '../components/atoms/PageLightbox'
import QuoteBlock from '../components/molecules/QuoteBlock'

function Project({data}) {
    const [open, setOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const {
        title,
        author,
        description,
        featureImage,
        lede,
        ogDescription,
        ogImage,
        ogTitle,
        ogUrl,
        date,
        content,
    } = data.contentfulProject

    const handleOpen = () => {
        setActiveIndex(0)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    let images = []
    Array({content: content, images: images})
    useEscapeKey({clickHandler: handleClose})

    return (
        <Container>
            <Seo title={title} description={description} author={author} ogDescription={ogDescription} ogTitle={ogTitle}
                 ogImage={ogImage} ogUrl={ogUrl}/>
            <FeatureImage featureImage={featureImage} small={false}/>
            {images.length > 0 &&
                <PageLightbox activeIndex={activeIndex} setActiveIndex={setActiveIndex} closeHandler={setOpen}
                              open={open} images={images}/>}
            <TitleBar lede={lede} title={title} project={true} author={author} date={date} clickHandler={handleOpen}
                      images={images}/>
            {content && content.map((item, index) => {
                return <React.Fragment key={index}>
                    {item.centeredContent &&
                        <CenteredContent content={item.centeredContent} setOpen={setOpen} images={images}
                                         setActiveIndex={setActiveIndex}/>}
                    {item.image && <ImageBlock image={item.image} setOpen={setOpen} images={images}
                                               setActiveIndex={setActiveIndex}/>}
                    {item.quote &&
                        <QuoteBlock quote={item.quote} role={item.quoteAuthorRole} author={item.quoteAuthor}/>}
                    {(item.subheading || item.bodyContent) &&
                        <TextBlock subheading={item.subheading} content={item.bodyContent} images={images}
                                   setActiveIndex={setActiveIndex} setOpen={setOpen}/>}
                </React.Fragment>
            })}
            <Next title={data.next.title} slug={'/projects/' + data.next.slug}/>
        </Container>
    )
}

export const pageQuery = graphql`
    query projectQuery($id: String! $nextPostId: String) {
        contentfulProject(id: {eq: $id}) {
            title
            id
            slug
            author
            date(formatString: "DD MMMM YYYY")
            description
            featureImage {
              gatsbyImageData
              title
              description
              height
              width
            }
            lede
            ogDescription
            ogImage {
              gatsbyImageData
              title
              description
              height
              width
            }
            ogTitle
            ogUrl
            content {
                centeredContent {
                    raw
                    references {
                        contentful_id
                        title
                        description
                        gatsbyImageData
                        __typename
                    }
                }
                image {
                    gatsbyImageData
                    contentful_id
                    title
                    description
                    height
                    width
                }
                quote {
                    raw
                }
                quoteAuthor
                quoteAuthorRole
                subheading
                bodyContent {
                    raw
                    references {
                        contentful_id
                        title
                        description
                        gatsbyImageData
                        __typename
                        height
                        width
                    }
                }
            }
        }
        next: contentfulProject(id: { eq: $nextPostId }) {
            title
            slug
            id
        }
    }
`

export default Project