import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const Holder = styled.div`
  border-top: 2px dotted ${props => props.theme.colours.darkgrey};
  margin-top: 9rem;
  padding: 3rem 0 0.5rem 0;
  font-family: Soehne;

  > a {
    text-decoration: none;
    transition: color 300ms ease-in-out;

    > h2 {
      margin-top: 0.25rem;
      ${(props) => props.theme.fluidType(4)};
    }

    &:hover {
      color: ${props => props.theme.colours.blue};
    }
  }
`

Next.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
}

function Next({title, slug}) {
    return (
        <Holder>
            <small style={{textTransform: 'uppercase'}}>next:</small>
            <Link to={slug}><h2>{title}</h2></Link>
        </Holder>
    )
}

export default Next