import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RichTextConverter from '../atoms/RichTextConverter'

const Holder = styled.div`
  position: relative;
  margin: 2rem 0.8rem 3rem 0.8rem;
`

const Inner = styled.div`
  color: ${props => props.theme.colours.blue};

  > :first-child {
    display: inline-flex;

    h1, h2, h3, h4, h5, h6, p {
      ${(props) => props.theme.fluidType(7)};
    }

    > * {
      text-indent: -0.4em;
      line-height: 1.2;

      &:before {
        ${(props) => props.theme.fluidType(7)};
        content: "“";
      }

      &:after {
        ${(props) => props.theme.fluidType(7)};
        content: "”";
      }
    }
  }

  > :nth-child(2), > :nth-child(3) {
    margin: 0;
  }

  > :nth-child(2) {
    margin-top: 1.5rem;
  }
`

QuoteBlock.propTypes = {
    quote: PropTypes.array.isRequired,
    author: PropTypes.string,
    role: PropTypes.string
}

function QuoteBlock({quote, author, role}) {
    return (
        <Holder>
            <Inner>
                <RichTextConverter content={quote}/>
                {author && <p>{author}</p>}
                {role && <p>{role}</p>}
            </Inner>
        </Holder>
    )
}

export default QuoteBlock