import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'

const Holder = styled.div`
  height: ${props => props.small ? '14rem' : '18rem'};
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

FeatureImage.propTypes = {
    featureImage: PropTypes.object.isRequired,
    small: PropTypes.bool.isRequired
}

FeatureImage.defaultProps = {
    small: false
}

function FeatureImage({featureImage, small}) {
    return (
        <Holder small={small}>
            <GatsbyImage style={{objectFit: 'cover'}} alt={featureImage.title}
                         image={featureImage.gatsbyImageData}/>
        </Holder>
    )
}

export default FeatureImage