import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Holder = styled.div`
  margin: 2rem 0 3rem 0;
  border-bottom: 2px solid ${props => props.theme.colours.black};
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 2rem 0 6rem 0;
  }

  > h1 {
    ${(props) => props.project ? props.theme.fluidType(9) : props.theme.fluidType(6)};
    margin: 0.5rem 0;
  }

  > p {
    ${(props) => props.theme.fluidType(1.25)};
    margin: 0.5rem 0;
  }

  > button {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    letter-spacing: .4px;
    border: 2px solid ${props => props.theme.colours.black};
    color: ${props => props.theme.colours.black};
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: ${props => props.theme.colours.blue};
      color: ${props => props.theme.colours.white};
      border: 2px solid ${props => props.theme.colours.blue};
    }
  }
`

const MetaData = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 1.5rem;

  small {
    margin-right: 1em;
    text-transform: initial;
    ${(props) => props.theme.fluidType(-2)};
    color: ${props => props.theme.colours.silver};
  }
`

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    lede: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    project: PropTypes.bool.isRequired,
    clickHandler: PropTypes.func.isRequired
}

function TitleBar({title, lede, date, author, duration, tag, project, clickHandler, images}) {
    return (
        <Holder project={project}>
            {project && <small
                style={{textTransform: 'uppercase', fontFamily: 'Soehne', letterSpacing: '0.07em'}}>project</small>}
            <h1>{title}</h1>
            <p style={{marginBottom: '3rem'}}>{lede}</p>
            {(images.length > 0 && project) && <button className="button" onClick={clickHandler}>View Images</button>}
            {!project && <MetaData>
                <small>{date}</small>
                <small>{author}</small>
                <small>{tag && tag.map((item, index) => <span key={index}>{(index ? ', ' : '') + item}</span>)}</small>
                <small>{duration}</small>
            </MetaData>}
        </Holder>
    )
}

export default TitleBar